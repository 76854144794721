<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div>{{ value ? value : 0 }} %</div>
    <el-slider
        :step="10"
        v-model="value"
    />
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {
    BaseFormItem,
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
